<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span v-if="stu == 'review'">
          <em>当前位置：</em>
          <a href="javascript:;">直播审核(小程序)</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">直播审核</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">审核</a>
        </span>
        <span v-else>
          <em>当前位置：</em>
          <a href="javascript:;">直播审核(小程序)</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">直播列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">新增直播</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              class
            >
              <el-form-item label="直播类型：" prop="liveType">
                <el-radio-group
                  v-model="ruleForm.liveType"
                  :disabled="disableds"
                >
                  <el-radio label="10">手机直播</el-radio>
                  <el-radio label="20">推流设备直播</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="直播间标题："
                class="form-item form-textarea form-control"
                prop="liveTitle"
              >
                <el-input
                  size="small"
                  :disabled="disableds"
                  placeholder="请输入直播间标题"
                  v-model="ruleForm.liveTitle"
                  maxlength="17"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item
                label="直播时间："
                class="form-item form-textarea"
                prop="liveTime"
              >
                <el-date-picker
                  v-model="ruleForm.liveTime"
                  size="small"
                  :disabled="disableds"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="直播老师昵称"
                class="form-item"
                prop="liveNickname"
              >
                <el-input
                  size="small"
                  :disabled="disableds"
                  placeholder="请输入直播老师昵称"
                  v-model="ruleForm.liveNickname"
                  maxlength="15"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="直播老师" class="form-item" prop="teacherId">
                <el-select
                  v-model="ruleForm.teacherId"
                  size="small"
                  placeholder="请选择直播老师"
                  :disabled="disableds"
                  @change="liveTeacherChange"
                >
                  <!-- <el-option
                    v-for="item in options"
                    :key="item.teacherId"
                    :label="item.teacherName"
                    :value="item.teacherId"
                  >
                  </el-option> -->
                  <el-option
      v-for="item in options"
      :key="item.teacherId"
      :label="item.teacherName + '--' + item.mobile"
      :value="item.teacherId">
      <span >{{ item.teacherName }}--{{ item.mobile }}</span>
    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" class="form-item" v-show="authenticationStatus">
                <div style="display:flex;color:red;" class="flexdc">
                  <span
                    ><i class="el-icon-warning-outline"></i
                    >主播未验证,扫码下方二维码进行身份验证。</span
                  >

                  <el-image
                    style="width: 150px; height: 150px"
                    :src="require('@/assets/live.png')"
                    :fit="fill"
                  ></el-image>
                  <span
                    >每个直播间需要绑定一个用作核实主播身份,不会展示给观众。小程序码5分钟内有效</span
                  >
                </div>
              </el-form-item>
              <el-form-item
                label="分享卡片封面："
                prop="shareCardCover"
                class="form-control"
              >
                <el-upload
                  :on-change="handlebusinessLicense1"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  :disabled="disableds"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      ruleForm.shareCardCover || require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
                <p>
                  最多只能上传1张,建议上传尺寸800*640px,图片大小不得超过1M。
                </p>
              </el-form-item>
              <el-form-item
                label="直播卡片封面："
                prop="liveCardCover"
                class="form-control"
              >
                <el-upload
                  :on-change="handlebusinessLicense2"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  :disabled="disableds"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      ruleForm.liveCardCover || require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
                <p>
                  最多只能上传1张,建议上传尺寸800*800px,图片大小不得超过1M。
                </p>
              </el-form-item>
              <el-form-item
                label="直播间背景图："
                prop="liveRoomBackground"
                class="form-control"
              >
                <el-upload
                  :on-change="handlebusinessLicense3"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  :disabled="disableds"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      ruleForm.liveRoomBackground ||
                        require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
                <p>
                  最多只能上传1张,建议上传尺寸600*1300px,图片大小不得超过3M。
                </p>
              </el-form-item>
              <el-form-item label="直播间功能" class="form-control">
                <el-checkbox v-model="ruleForm.livePl" :disabled="disableds"
                  >评论</el-checkbox
                >
                <el-checkbox v-model="ruleForm.liveFx" :disabled="disableds"
                  >分享</el-checkbox
                >
                <el-checkbox v-model="ruleForm.liveDz" :disabled="disableds"
                  >点赞</el-checkbox
                >
                <el-checkbox
                  v-model="ruleForm.enablePlayback"
                  :disabled="disableds"
                  >回放</el-checkbox
                >
                <el-checkbox v-model="ruleForm.liveMm" :disabled="disableds"
                  >设置直播间密码</el-checkbox
                >
                <el-input
                  v-if="ruleForm.liveMm"
                  size="small"
                  :disabled="disableds"
                  placeholder="请输入直播间密码"
                  v-model="ruleForm.liveMmValue"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="审核状态"
                prop="liveRoomFunction"
                class="form-control"
                v-if="stu == 'review'"
              >
                <el-radio-group
                  v-model="radio"
                  @change="RadioChange"
                  :disabled="name != 'first'"
                >
                  <el-radio label="30">通过</el-radio>
                  <el-radio label="40">不通过</el-radio>
                </el-radio-group>
                <div
                  style="display:flex;padding-top:.5rem;"
                  v-show="radio == '40'"
                >
                  <el-input
                    type="textarea"
                    :disabled="name != 'first'"
                    placeholder="请输入不通过原因"
                    v-model="textarea"
                    ref="textarea"
                    maxlength="100"
                    show-word-limit
                  />
                </div>
              </el-form-item>
            </el-form>
            <div class="activeSetbtn" v-if="stu == 'review'">
              <el-button class="bgc-bv" @click="doCancel">取消</el-button>
              <el-button
                class="bgc-bv"
                @click="doSure('review')"
                v-if="name == 'first'"
                >审核</el-button
              >
            </div>
            <div class="activeSetbtn" v-else>
              <el-button class="bgc-bv" @click="doCancel1">取消</el-button>
              <el-button class="bgc-bv" @click="doSure('save', '10')" v-if="stu != 'detail'"
                >保存</el-button
              >
              <el-button class="bgc-bv" @click="doSure('submit', '20')" v-if="stu != 'detail'"
                >提交审核</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "CreateShareActivity",
  components: {},
  mixins: [List],
  data() {
    return {
      ruleForm: {
        liveType: "10", //直播类型
        liveTitle: "", //直播间标题
        liveTime: "", //直播直播时间
        liveNickname: "", //直播老师昵称
        teacherId: "",
        shareCardCover: "", //分享卡片封面
        liveCardCover: "", //直播卡片封面
        liveRoomBackground: "", //直播间背景墙
        //直播间功能
        livePl: true,
        liveFx: true,
        liveDz: true,
        enablePlayback: true,
      },
      rules: {
        liveType: [
          { required: true, message: "请选择直播类型", trigger: "blur" },
        ],
        liveTitle: [
          { required: true, message: "请输入直播间标题", trigger: "blur" },
          {
            min: 3,
            max: 17,
            message:
              "直播标题必须为3到17个字",
            trigger: "blur",
          },
        ],
        liveTime: [
          { required: true, message: "请选择直播时间", trigger: "change" },
        ],
        liveNickname: [
          { required: true, message: "请输入直播老师昵称", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "主播昵称必须为2-15个字",
            trigger: "blur",
          },
        ],
        teacherId: [
          {
            required: true,
            message: "请选择直播老师",
            trigger: "change",
          },
        ],
        shareCardCover: [
          { required: true, message: "请上传分享卡片封面", trigger: "change" },
        ],
        liveCardCover: [
          { required: true, message: "请上传直播卡片封面", trigger: "change" },
        ],
        liveRoomBackground: [
          { required: true, message: "请上传直播间背景墙", trigger: "change" },
        ],
      },
      //直播老师数据
      options: [],
      radio: "30", //审核状态
      textarea: "", //不通过原因
      disableds: false,
      review: "reviews", //判断审核还是新增
      name: "",
      authenticationStatus:false
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    if(this.stu == 'review') {
       this.review = JSON.parse(this.$route.query.getClassInfo).review || "review";
    this.name = JSON.parse(this.$route.query.getClassInfo).name;
    }
    this.activeState = this.$route.query.activeState;
    this.getSelectTeacher();
    if (this.review == "review") {
      this.disableds = true;
      this.liveId = JSON.parse(this.$route.query.getClassInfo).liveId;
      this.getDetails(this.liveId);
    }
    if (this.stu == "edit") {
      this.liveId = this.$route.query.liveId;
      this.getDetails(this.liveId);
    }
    if (this.stu == "detail") {
       this.disableds = true;
      this.liveId = this.$route.query.liveId;
      this.getDetails(this.liveId);
    }
  },
  computed: {},
  methods: {
    //回显数据
    getDetails(liveId) {
      this.$post(
        "/room/miniapp-live-room/getInfo",
        {
          liveId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          this.ruleForm = {
            ...this.ruleForm,
            ...ret.data,
            liveTime: [
              ret.data.startTime.replaceAll("/", "-"),
              ret.data.endTime.replaceAll("/", "-"),
            ],
            livePl: ret.data.miniappLiveFeature.livePl,
            liveFx: ret.data.miniappLiveFeature.liveFx,
            liveDz: ret.data.miniappLiveFeature.liveDz,
            enablePlayback: ret.data.miniappLiveFeature.liveHf,
            liveMm: ret.data.miniappLiveFeature.liveMm,
            liveMmValue: ret.data.miniappLiveFeature.liveMmValue,
            shareCardCover: ret.data.shareCoverUrl,
            shareCardCoverKey: ret.data.shareCover,
            liveCardCover: ret.data.cardCoverUrl,
            liveCardCoverKey: ret.data.cardCover,
            liveRoomBackground: ret.data.bgCoverUrl,
            liveRoomBackgroundKey: ret.data.bgCover,
          };
          this.radio = ret.data.auditState  == '20' ? '': ret.data.auditState ;
          this.textarea = ret.data.auditReason;
        })
        .catch((err) => {
          return;
        });
    },
    /* 分享卡片封面图片上传  */
    handlebusinessLicense1(res) {
      this.uploadFetch1(res.raw);
    },
    uploadFetch1(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.shareCardCover = ret.data.fileURL || "";
        that.ruleForm.shareCardCoverKey = ret.data.fileKey || "";
      });
    },
    /* 直播卡片封面图片上传  */
    handlebusinessLicense2(res) {
      this.uploadFetch2(res.raw);
    },
    uploadFetch2(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.liveCardCover = ret.data.fileURL || "";
        that.ruleForm.liveCardCoverKey = ret.data.fileKey || "";
      });
    },
    /* 直播间背景墙图片上传 */
    handlebusinessLicense3(res) {
      this.uploadFetch3(res.raw);
    },
    uploadFetch3(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.liveRoomBackground = ret.data.fileURL || "";
        that.ruleForm.liveRoomBackgroundKey = ret.data.fileKey || "";
      });
    },
    /* 直播老师选择事件 */
    liveTeacherChange(val) {
      console.log(val);
      this.options.map(el => {
        el.teacherId == val
        if(el.teacherId == val) {
          if(el.status !== '1' ) {
              this.authenticationStatus = true
          } else {
            this.authenticationStatus = false
          }
        }
      })
      // this.$post("", {}).then((ret) => {
      //   if (ret.status == "0") {
      //     if (!ret.dd) {
      //     }
      //   }
      // });
    },
    /* 确定 */
    doSure(stu, auditState) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.stu == "add" ||this.stu == "edit" || stu == 'save' || stu == 'submit') {
            this.auditState = auditState;
            this.doAjax();
          } else if (this.stu == "review") {
             if(this.radio == '') {
            this.$message({
                message:'请选择审核状态',
                type:"error"
              })
              return false
            } 
            if(this.radio == '40') {
              if(!this.textarea) {
              this.$message({
                message:'请输入不通过原因',
                type:"error"
              })
              return false
            }
            } 
               this.doAjaxReview();
            
           
          }
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    /* 新增--编辑 -- 保存 */
    doAjax() {
      let paramr = {
        auditState: this.auditState,
        liveType: this.ruleForm.liveType,
        liveTitle: this.ruleForm.liveTitle,
        startTime: this.ruleForm.liveTime[0],
        endTime: this.ruleForm.liveTime[1],
        liveNickname: this.ruleForm.liveNickname,
        teacherId: this.ruleForm.teacherId,
        shareCover: this.ruleForm.shareCardCoverKey,
        cardCover: this.ruleForm.liveCardCoverKey,
        bgCover: this.ruleForm.liveRoomBackgroundKey,
        miniappLiveFeature: {
          livePl: this.ruleForm.livePl,
          liveDz: this.ruleForm.liveDz,
          liveFx: this.ruleForm.liveFx,
          liveHf: this.ruleForm.enablePlayback,
          liveMm: this.ruleForm.liveMm,
          liveMmValue: this.ruleForm.liveMm ? this.ruleForm.liveMmValue : "",
        },
      };
      if (this.liveId) {
        paramr.liveId = this.liveId;
      }
      this.$post(
        this.stu != "edit"
          ? "/room/miniapp-live-room/insert"
          : "/room/miniapp-live-room/modify",
        paramr,
        3000,
        true,
        2
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: this.stu == "edit" ? "修改成功" : "创建成功",
              type: "success",
            });
            this.$router.push({
              path: "/web/miniApp/livelist",
              query: {
                refresh: true,
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 审核 */
    doAjaxReview() {
      let paramr = {
        auditState: this.radio,
        auditReason: this.textarea,
        liveTitle: this.ruleForm.liveTitle,
        liveId: JSON.parse(this.$route.query.getClassInfo).liveId,
      };
      this.$post("/room/miniapp-live-room/auditLive", paramr, 3000, true, 2)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: this.stu == "edit" ? "修改成功" : "创建成功",
              type: "success",
            });
            this.$router.push({
              path: "/web/miniApp/liveReview",
              query: {
                active: this.name ? this.name : "second",
                refresh: true,
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    getSelectTeacher() {
      this.$post("/miniapp/liveteacher/selectLiveList", {}, 3000, true, 2).then(
        (ret) => {
          this.options = ret.data;
        }
      );
    },
    /* 取消 */
    doCancel() {
      this.$router.push({
        path: "/web/miniApp/liveReview",
        query: {
          active: this.name ? this.name : "second",
          refresh: true,
        },
      });
    },
    /* 取消--直播列表 */
    doCancel1() {
      this.$router.push({
        path: "/web/miniApp/livelist",
        query: {
          refresh: true,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.form-textarea {
  .el-textarea {
    .el-textarea__inner {
      min-height: 13rem !important;
      resize: none;
    }
  }
}
.activeSetbtn {
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
}
.integral {
  display: flex;
  margin-bottom: 0.75rem;
  align-items: center;
  .integralTitle {
    min-width: 4rem;
  }
  > div:nth-child(3) {
    padding-left: 1.5rem;
  }
}
// .el-form-item {
//   display: flex;
//   align-items: center;
//   .el-form-item__content {
//     width: 100%;
//     margin-left: 0 !important;
//   }
// }
.form-control {
  align-items: flex-start;
}
.form-integral {
  align-items: flex-start;
  .form-integralbox {
    padding-bottom: 0.75rem;
  }
}
.form-itemDate {
  width: 50%;
}
</style>
<style lang="less">
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
</style>
